@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "FS Elliot Pro";
  src: url("../src/assets/fonts/FS Elliot Pro-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "FS Elliot Pro";
  src: url("../src/assets/fonts/FS Elliot Pro-Bold.otf") format("opentype");
  font-weight: 700;
}

@layer components {
  .box-container {
    @apply flex box-border max-w-full min-w-0 min-h-0 flex-col;
  }
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  font-family: "FS Elliot Pro";
  min-height: 100vh;
}

html,
body {
  /* iOS PWA viewport bug fix */
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}
